import _, { debounce } from 'lodash'
import React, { useEffect, useState, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { makeRequest } from '../../helpers'
import KeyTermsView from './key-terms-view'

const KeyTermsController = () => {
  const { i18n } = useTranslation()
  const [loading, setLoading] = useState(false)
  const [categories, setCategories] = useState([])
  const [terms, setTerms] = useState([])
  const [letters, setLetters] = useState([])
  const [filters, setFilters] = useState({
    letter: null,
    category: 'travel',
    s: null,
    page: 1,
  })

  const getTerms = async () => {
    setLoading(true)

    const headers = {
      'Content-Type': 'application/json',
    }
    makeRequest({
      headers,
      endPoint: 'keywords',
      params: {
        ...filters,
        lang: i18n.language,
      },
    })
      .then((resp) => {
        setTerms(resp.data.keywords)
        setLetters(resp.data.letters)
        setCategories(resp.data.categories)
        setLoading(false)
      })
      .catch((error) => {
        console.error(error)
        setLoading(false)
      })
  }

  const onHandleFilterByLetter = (letter) => {
    let updatedValue = {}
    updatedValue = { letter: _.toLower(letter) }
    setFilters((oldState) => ({
      ...oldState,
      ...updatedValue,
    }))
  }

  const onHandleFilterByCategory = (category) => {
    let updatedValue = {}
    updatedValue = { category }
    setFilters((oldState) => ({
      ...oldState,
      ...updatedValue,
    }))
  }

  const onHandleFilterByQuery = async (e) => {
    let updatedValue = {}
    updatedValue = { s: e.target.value }
    setFilters((oldState) => ({
      ...oldState,
      ...updatedValue,
    }))
  }
  const debouncedChangeHandler = useCallback(
    debounce(onHandleFilterByQuery, 500),
    [],
  )

  useEffect(() => {
    getTerms()
  }, [])

  useEffect(() => {
    getTerms()
  }, [filters])

  const viewProps = {
    data: terms,
    letters,
    loading,
    categories,
    onHandleFilterByLetter,
    onHandleFilterByCategory,
    debouncedChangeHandler,
  }
  return <KeyTermsView {...viewProps} />
}

export default KeyTermsController
